import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import {Container, Row, Col} from "react-bootstrap"

class Shooting extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Shooting" />
        <Container>
          <Row>
            <Col sm={12}>
            <h1 className="pt-5">Want to go shooting in The Lake District?</h1>
            <h5>Here are some useful resources:</h5>
            <ul>
              <li><a href="https://www.thelakedistrict.org/things-to-do/outdoor-activities/shooting/">Outdoor Activities: Shooting - thelakdistrict.org</a></li>
            </ul>
            </Col>
          </Row>
        </Container>
      </Layout>
    )
  }
}

export default Shooting

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
